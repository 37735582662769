@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: hsl(0deg 0% 100%);
  background-color: hsl(22.17deg 82.2% 62.55%);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px 0;
  width: 100%;
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: white;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1;
  }
  & .sectionInfo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & .productGallery {
      width: 112px;
      height: 112px;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
      & div {
        height: 100%;
      }
      & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .userInfo {
      width: calc(100% - 112px);
      padding-left: 20px;
      & > h1 {
        color: var(--colorBlack);
        margin: 0 0 8px 0;
        padding: 0;
        font-size: 24px;
        line-height: 120%;
        font-weight: var(--fontWeightBold);

        @media (--viewportLarge) {
          font-size: 28px;
        }
      }
      & > p {
        color: var(--marketplaceColor);
        font-size: 16px;
        line-height: 120%;
        margin: 0;
        padding: 0;
        text-transform: capitalize;
      }
    }
  }
  & .sectionText {
    margin-bottom: 20px;
    & > p {
      color: var(--colorGrey500);
      line-height: 26px;
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      margin: 0;
      padding: 0;
    }
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
    position: sticky;
    align-self: flex-start;
    top: 100px;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;
  padding: 34px 24px 0 24px;
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    height: calc(100vh - 100px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mobileHeading {
  margin: 0 0 14px 0;
  color: var(--colorBlack);

  @media (--viewportMedium) {
    margin: 0 0 14px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.pdfContainer {
  & h2 {
    font-size: 28px;
    color: black;
  }
  & :global(.react-pdf__Page__canvas) {
    width: 100% !important;
    height: auto !important;
  }
  & :global(.react-pdf__Page__textContent) {
    display: none;
    background-color: var(--colorWhite);
    & > span {
      font-size: 14px !important;
      display: inline-block;
      width: 100%;
      margin-bottom: 8px;
      text-align: left;
      transform: none !important;
      padding: 0 24px;
    }
    & > span + br {
      display: none;
    }
  }
  & :global(.react-pdf__Page__annotations) {
    height: 100% !important;
    width: 100% !important;
  }
}
.sectionReviews {
  margin-top: 32px;
  padding-top: 32px;
  border-top: solid 1px var(--colorGrey100);
  width: 100%;
  float: left;
  & > h2 {
    margin: 0 0 10px 0;
    color: var(--colorBlack);
    line-height: 130%;
    font-size: 24px;
    padding: 0;
    @media (--viewportLarge) {
      font-size: 28px;
    }
  }
}
.moreInfo {
  margin-bottom: 24px;
  margin-top: 32px;
  padding-top: 32px;
  border-top: solid 1px var(--colorGrey100);
  width: 100%;
  float: left;
  & > h2 {
    margin: 0 0 10px 0;
    color: var(--colorBlack);
    line-height: 130%;
    font-size: 24px;
    padding: 0;
    @media (--viewportLarge) {
      font-size: 28px;
    }
  }
  & .more {
    margin-top: 24px;
    & > svg {
      margin-right: 10px;
    }
  }
}
.sectionDetails {
  margin-bottom: 24px;
  margin-top: 32px;
  padding-top: 32px;
  border-top: solid 1px var(--colorGrey100);
  width: 100%;
  float: left;
  & > h2 {
    margin: 0 0 10px 0;
    color: var(--colorBlack);
    line-height: 130%;
    font-size: 24px;
    padding: 0;
    @media (--viewportLarge) {
      font-size: 28px;
    }
  }
  & .expertisePills {
    border: 1px solid #dedede;
    padding: 6px 15px;
    line-height: 120%;
    font-size: 14px;
    color: var(--colorGrey500);
    border-radius: 100px;
    display: inline-block;
    margin: 0 10px 10px 0;
    &:nth-child(1n) {
      background-color: #f9f6cd;
      color: #999009;
      border-color: #999009;
    }
    &:nth-child(2n) {
      background-color: #f7e9e9;
      color: #c72626;
      border-color: #c72626;
    }
    &:nth-child(3n) {
      background-color: #eaf7e9;
      color: #26c72b;
      border-color: #26c72b;
    }
    &:nth-child(4n) {
      background-color: #ece9f7;
      color: #4326c7;
      border-color: #4326c7;
    }
    &:nth-child(5n) {
      background-color: #f7e9f4;
      color: #c726b4;
      border-color: #c726b4;
    }
  }
}
.educationSec {
  margin-top: 32px;
  border-top: solid 1px var(--colorGrey100);
  padding-top: 32px;
  width: 100%;
  float: left;
  & > h2 {
    margin: 0 0 10px 0;
    color: var(--colorBlack);
    line-height: 130%;
    font-size: 24px;
    padding: 0;
    @media (--viewportLarge) {
      font-size: 28px;
    }
  }
  & .educationBlock {
    padding: 24px;
    border: solid 1px var(--colorGrey100);
    background-color: hsl(240deg 3.04% 97.24%);
    margin-bottom: 32px;
    display: flex;
    align-items: flex-start;
    &:last-child {
      margin-bottom: 24px;
    }
    & .educationIcon {
      & > svg {
        & path {
          fill: var(--marketplaceColor);
        }
      }
    }
    & .educationDetails {
      width: calc(100% - 40px);
      padding-left: 20px;
      & > h3 {
        font-size: 18px;
        line-height: 24px;
        color: var(--colorBlack);
        padding: 0;
        margin: 0 0 10px 0;
      }
      & > p {
        margin: 0 0 10px 0;
        color: var(--colorGrey500);
        font-weight: 400;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.container {
  width: 100%;
  position: relative;

  & .shape1 {
    position: fixed;
    left: 0px;
    top: 70px;
    opacity: 1;
    text-align: left;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportLarge) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    & > img {
      max-width: 40%;
    }
  }
  & .shape2 {
    position: fixed;
    right: 0px;
    top: 70px;
    opacity: 1;
    text-align: right;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportLarge) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
    & > img {
      max-width: 40%;
    }
  }
  & .shape3 {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    opacity: 1;
    text-align: left;
    z-index: 0;
    display: none;

    @media (--viewportLarge) {
      display: block;
    }
    & > img {
      max-width: 40%;
    }
  }
  & .shape4 {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    opacity: 1;
    text-align: right;
    z-index: 0;
    display: none;

    @media (--viewportLarge) {
      display: block;
    }
    & > img {
      max-width: 40%;
    }
  }
  & .shape5 {
    position: fixed;
    left: 0px;
    bottom: 0px;
    opacity: 1;
    text-align: left;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportLarge) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
    & > img {
      max-width: 40%;
    }
  }
  & .shape6 {
    position: fixed;
    right: 0px;
    bottom: 0px;
    opacity: 1;
    text-align: right;
    z-index: 0;
    display: none;
    height: 100vh;

    @media (--viewportLarge) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    & > img {
      max-width: 40%;
    }
  }
}

.officeAddress {
  margin-top: 12px !important;
  font-weight: 500;
  color: #a1a1a7 !important;
}
